export const ROW_PAGE = 50;
export const TIMEOUT_REQ = 1000;

export const collectionStatus: any = {
  '0': { name: 'Створено', color: 'blue' },
  '1': { name: 'Переданий', color: 'gray' },
  '2': { name: 'Активовано', color: 'green' },
  '3': { name: 'Заблокований', color: 'red' },
  '4': { name: 'Деактивований', color: 'orange' },
};
