import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { StylesTable } from '../Stylestable';
import TextField from '@mui/material/TextField';
import { IProduct } from '../../store/products/types';
import { ListSubProducts } from './ListSubProducts';
import { useSearch } from '../../hooks/useSearch';
import CircularProgress from '@mui/material/CircularProgress';
import { EnhancedTableHead } from '../ui';
import { Order, stableSort } from '../../utils/stableSort';
import { GetProducts } from '../../store/products/actions';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { EditProducts } from './EditProducts';
import roles from '../../utils/roles';

interface Column {
  id: 'absnum' | 'subName' | 'name' | 'url' | 'children' | 'edit';
  label: string;
  sort?: boolean;
  align?: 'right' | 'left' | 'center';
  width?: string;
  roles?: number[];
}

const sort = true;

const columns: Column[] = [
  { id: 'absnum', label: 'ID', align: 'center', width: '5%', sort },
  { id: 'name', label: 'Головний продукт', align: 'left', sort },
  { id: 'subName', label: 'Назва для виводу', align: 'left', sort },
  { id: 'url', label: 'URL', align: 'left', sort },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '10%',
    roles: [roles.ADMIN, roles.MARKETER],
  },
  {
    id: 'children',
    label: 'Версії продукту',
    align: 'center',
    width: '11%',
  },
];

type PropsType = {};

export const ListAllProducts: React.FC<PropsType> = () => {
  const [editData, setEditData] = React.useState<{ data?: IProduct | null } | null>(null);
  const [product, setProduct] = React.useState<IProduct | null>(null);
  const [id, setId] = React.useState('');
  const [name, setName] = React.useState('');

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof IProduct>('absnum');

  const { Products, Sidebar, Auth } = useSelector((store: AppStore) => store);

  const classesTable = StylesTable();

  const { loading, handleSearch } = useSearch({
    SearchData: GetProducts,
  });

  React.useEffect(() => {
    if (Sidebar.page.type) {
      setProduct(null);
    }
  }, [Sidebar.page]);

  React.useEffect(() => {
    if(Products.data && Products.data.ip){
      console.log('Your ip :' + Products.data.ip);

    }
    if (Products.data && product) {
      const prod = Products.data.products.find((it) => it.absnum === product?.absnum);
      if (prod) {
        setProduct(prod);
      }
    }
  }, [Products.data, product]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IProduct) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (editData) {
    return <EditProducts titlePage={'Редагувати головний продукт'} data={editData.data} parentId={0} handleCancel={() => setEditData(null)} />;
  }

  if (product) {
    return <ListSubProducts product={product} handleBack={() => setProduct(null)} />;
  }
  return (
    <>
      <Box>
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id"
          label="Пошук по ID"
          variant="outlined"
          value={id}
          className={classesTable.searchField}
          onChange={(e) => {
            setId(e.target.value);
            handleSearch(e.target.value, 'id');
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id-2"
          label="Назва продукту"
          variant="outlined"
          value={name}
          className={classesTable.searchField}
          onChange={(e) => {
            setName(e.target.value);
            handleSearch(e.target.value, 'name');
          }}
        />
        {loading ? <CircularProgress size={25} /> : null}
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label="sticky table">
          <EnhancedTableHead order={order} orderBy={orderBy} rights={Auth.data?.rights} onRequestSort={handleRequestSort} columns={columns} />
          <TableBody>
            {Products.data &&
              stableSort(Products.data.products, order, orderBy).map((row, i) => {
                return (
                  <TableRow style={{ maxHeight: 50 }} hover role="checkbox" tabIndex={-1} key={`${i}-${row.absnum}`}>
                    <TableCell align="center">{row.absnum}</TableCell>
                    <TableCell className={classesTable.row}>{row.name}</TableCell>
                    <TableCell className={classesTable.row}>{row.subName}</TableCell>
                    <TableCell className={classesTable.row}>{row.url}</TableCell>
                    {Auth.data?.rights[roles.ADMIN] || Auth.data?.rights[roles.MARKETER] ? (
                      <TableCell align="center">
                        <IconButton aria-label="edit" onClick={() => setEditData({ data: row })}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    ) : null}
                    <TableCell align="center">
                      {row.parentId === 0 && row.children.length > 0 ? (
                        <Button style={{width:'100%', fontSize :'0.81rem'}} variant="contained" color="primary" onClick={() => setProduct(row)}
                        >
                          {`Перейти (${row.children.length})`}
                        </Button>
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
