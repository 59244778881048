import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { IProduct, TProductsState } from './types';

const GetProductsAction = new Actions('GET_PRODUCTS', ActionTypes);
export interface TypeGetProductsR extends TDefRequest {
  data: {
    id?: number;
    name?: string;
    subName?: string;
    productId?: number;
    url?: string;
    page?: number;
  };
}
export const GetProducts = {
  request: (payload: TypeGetProductsR) => GetProductsAction.request(payload),
  success: (payload: TProductsState['data']) => GetProductsAction.success(payload || {}),
  error: (message: string) => GetProductsAction.error(message),
};

const SearchProductsAction = new Actions('SEARCH_PRODUCTS', ActionTypes);
export interface TypeSearchProductsR extends TDefRequest {
  data: {
    id?: number;
    name?: string;
    subName?: string;
    productId?: number;
    url?: string;
    page?: number;
  };
}
export const SearchProducts = {
  request: (payload: TypeSearchProductsR) => SearchProductsAction.request(payload),
  success: (payload: TProductsState['data']) => SearchProductsAction.success(payload || {}),
  error: (message: string) => SearchProductsAction.error(message),
};

const UpdateProductAction = new Actions('UPDATE_PRODUCT', ActionTypes);
export interface TypeUpdateProductR extends TDefRequest {
  id: number;
  data: {
    name: string;
    subName: string;
    url: string;
  };
}
export const UpdateProduct = {
  request: (payload: TypeUpdateProductR) => UpdateProductAction.request(payload),
  success: (payload: IProduct) => UpdateProductAction.success(payload || {}),
  error: (message: string) => UpdateProductAction.error(message),
};
